<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.default_journalist_setting') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_sales_invoice" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_sales_invoice_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_sales_refund" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_sales_refund_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_sales_credit_note" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_sales_credit_note_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_purchase_invoice" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_purchase_invoice_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_purchase_refund" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_purchase_refund_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_open_stock_qty" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_open_stock_qty_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_inventory_transactions" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_inventory_transactions_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_open_balance_supplier" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_open_balance_supplier_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_open_balance_customer" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_open_balance_customer_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_outcome" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_outcome_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_income" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_income_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_expenses" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_expenses_journalist') }}
                </b-form-checkbox>
              </div>
              <div class="col-md-12 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_apply_revenue" name="check-button" switch>
                  {{ $t('default_journalist_setting.is_apply_revenue_journalist') }}
                </b-form-checkbox>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "from-default-journalist-setting",
  data() {
    return {
      mainRoute: 'accounting/default-journalist-settings',
      mainRouteDependency: 'base/dependency',

      data: {
        is_apply_sales_invoice: false,
        is_apply_sales_refund: false,
        is_apply_sales_credit_note: false,
        is_apply_purchase_invoice: false,
        is_apply_purchase_refund: false,
        is_apply_open_stock_qty: false,
        is_apply_inventory_transactions: false,
        is_apply_open_balance_supplier: false,
        is_apply_open_balance_customer: false,
        is_apply_outcome: false,
        is_apply_income: false,
        is_apply_expenses: false,
        is_apply_revenue: false,
      },
      isEditing: false,
      validation: null,
    };
  },
  methods: {
    save() {

      // if (this.isEditing) {
      //     this.update();
      // } else {
      this.create();
      // }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        Vue.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        Vue.prototype.$postStatus = true;
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    getData() {
      ApiService.get(this.mainRoute).then((response) => {
        this.isEditing = true;
        this.data.is_apply_sales_invoice = response.data.data.is_apply_sales_invoice;
        this.data.is_apply_sales_refund = response.data.data.is_apply_sales_refund;
        this.data.is_apply_sales_credit_note = response.data.data.is_apply_sales_credit_note;
        this.data.is_apply_purchase_invoice = response.data.data.is_apply_purchase_invoice;
        this.data.is_apply_purchase_refund = response.data.data.is_apply_purchase_refund;
        this.data.is_apply_open_stock_qty = response.data.data.is_apply_open_stock_qty;
        this.data.is_apply_inventory_transactions = response.data.data.is_apply_inventory_transactions;
        this.data.is_apply_open_balance_supplier = response.data.data.is_apply_open_balance_supplier;
        this.data.is_apply_open_balance_customer = response.data.data.is_apply_open_balance_customer;
        this.data.is_apply_outcome = response.data.data.is_apply_outcome;
        this.data.is_apply_income = response.data.data.is_apply_income;
        this.data.is_apply_expenses = response.data.data.is_apply_expenses;
        this.data.is_apply_revenue = response.data.data.is_apply_revenue;
      });
    },
  },

  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.chart_accounts"), route:'/accounting/chart-accounts'}, {title: this.$t('chart_accounts.chart_accounts_settings')}]);
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.default_journalist_setting")}]);
    this.getData();

  },
};
</script>


